import React, {PureComponent, Fragment} from 'react';
import './Footer.scss';
import {inject, observer} from "mobx-react";
import Interweave from 'interweave';

@inject('Store')
@observer
class Footer extends PureComponent {

	state = {
		visible: false
	}

	onHandleClick = () => {
		this.setState({...this.state, visible: true})
	}

	cancelExit = () => {
		this.setState({...this.state, visible: false})
	}

	onHandleSubmit = () => {
		window.location.replace('http://www.berlin-chemie.ru/');
	}

	render() {
		const {Store} = this.props;
		return (
			<footer>
				<Fragment>
					<div className={`message_box ${this.state.visible ? 'active' : ''}`}>
						<div className="title">Вы покидаете тироксин.рф и переходите на сайт berlin‑chemie.ru</div>
						<div className="buttons">
							<button onClick={this.onHandleSubmit} className="white_button">Войти</button>
							<button onClick={() => this.cancelExit()} className="border_button">Отменить</button>
						</div>
					</div>
					<div onClick={this.cancelExit} className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/>
				</Fragment>
				<div className="footer_block_logo">
					{Store.footer.zagolovok_pered_logotipom}
					<img onClick={this.onHandleClick} src={Store.footer.logotip_v_futere} alt="Берлин-Хеми/А.Менарини"/>
				</div>
				<div className="footer_block_content"><Interweave content={Store.footer.spisok} /></div>
			</footer>
		);
	}
}

export default Footer;

import React, {PureComponent} from 'react';
import './Header.scss';
import loginIcon from './pic/login-icon.svg';
import CenterBlock from "../CenterBlock";
import { Link } from "react-scroll";
import {inject, observer} from "mobx-react";
import Interweave from 'interweave';
import showMore from './pic/show-more-icon.svg';

@inject('Store')
@observer
class Header extends PureComponent {
	constructor(props) {
		super(props);
		this.state.width = document.body.clientWidth;
	}

	state = {
		width: 0,
	}

	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.fon_v_shapke})`,
		}
		return (
			<section style={style} className="header_block">
				<CenterBlock>
					<header>
						<a href="https://berlinmed.loftweb.ru/" rel="noopener noreferrer" target="_blank">
							<img src={loginIcon} alt="Вход для специалистов"/>
							Вход для специалистов
						</a>
					</header>
					<div className="content_block">
						<h1><Interweave noWrap={true} content={Store.content.zagolovok_v_shapke} /></h1>
						<div className="content">
							<p>
								{Store.content.tekst_pod_zagolovkom_v_shapke}
							</p>
						</div>
						<div className="header_block_buttons">
							<Link smooth={true} offset={-50} to="about_content" className="header_show_more">Узнать подробнее</Link>
							<Link smooth={true} offset={-50} to="test" className="test_button">Пройти тестирование</Link>
						</div>
					</div>
				</CenterBlock>
				<div className="show_more_icon">
					<Link smooth={true} offset={-50} to="about_content">
						<img src={showMore} alt="Скролл вниз"/>
					</Link>
				</div>
			</section>
		);
	}
}

export default Header;

import React, {PureComponent, Fragment} from 'react';
import './App.scss';
import Header from "../Header";
import AboutContent from "../AboutContent";
import IconBox from "../IconBox";
import VideoBlock from "../VideoBlock";
import TestButtonSection from "../TestButtonSection";
import CenterBlock from "../CenterBlock";
import Test from "../Test";
import Footer from "../Footer";
import MediaQuery from 'react-responsive'
import {inject, observer} from "mobx-react";
import HeaderMobile from "../HeaderMobile";

@inject('Store')
@observer
class App extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.GET('pages/9').then(r => Store.content = r);
		Store.GET('pages/41').then(r => Store.footer = r);
	}

	render() {
		const {Store} = this.props;
		return (
			Store.content ? (
				<Fragment>
					<MediaQuery maxWidth={600}>
						<HeaderMobile/>
					</MediaQuery>

					<MediaQuery minWidth={601}>
						<Header/>
					</MediaQuery>

					<CenterBlock>
						<AboutContent/>
						<IconBox/>
						<VideoBlock/>
						<TestButtonSection/>
						<Test/>
						{Store.footer ? <Footer/> : null}
					</CenterBlock>
				</Fragment>
			) : null
		);
	}
}

export default App;

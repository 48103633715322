import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from "mobx-react";
import Store from './store/Store';
import {ParallaxProvider} from 'react-scroll-parallax';

const Root = (
	<Provider Store={Store}>
		<ParallaxProvider>
			<App/>
		</ParallaxProvider>
	</Provider>
);

ReactDOM.render(Root, document.getElementById('root'));

import React, {PureComponent} from 'react';
import loginIcon from "../Header/pic/login-icon.svg";
import CenterBlock from "../CenterBlock";
import Interweave from "interweave";
import {Link} from "react-scroll";
import {inject, observer} from "mobx-react";
import '../Header/Header.scss'

@inject('Store')
@observer
class HeaderMobile extends PureComponent {
	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.fon_v_shapke_mob})`,
		}
		return (
			<section style={style} className="header_mobile">
				<CenterBlock>
					<div className="header_mobile_position">
						<header>
							<a href="https://berlinmed.loftweb.ru/" rel="noopener noreferrer" target="_blank">
								<img src={loginIcon} alt="Вход для специалистов"/>
								Вход для специалистов
							</a>
						</header>
						<div className="content_block">
							<h1><Interweave content={Store.content.zagolovok_v_shapke} /></h1>
							<div className="content">
								<p>
									{Store.content.tekst_pod_zagolovkom_v_shapke}
								</p>
							</div>
						</div>
						<div className="header_block_buttons">
							<Link smooth={true} offset={-50} to="about_content" className="header_show_more">Узнать подробнее</Link>
							<Link smooth={true} offset={-50} to="test" className="test_button">Пройти тестирование</Link>
						</div>
					</div>
				</CenterBlock>
			</section>
		);
	}
}

export default HeaderMobile;

import React, {PureComponent} from 'react';
import './AboutContent.scss';
import AboutContentItem from "./AboutContentItem";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class AboutContent extends PureComponent {

	render() {
		const {Store} = this.props;
		return (
			<section id="about_content" className="about_content">
				{
					Store.content.tekstovyj_blok.map((item, index) => {
						return (
							<AboutContentItem
								key={index}
								title={item.zagolovok}
								content={item.kratkoe_opisanie}
								pic={item.kartinka}
								modalContent={item.kontent}
							/>
						)
					})
				}
			</section>
		);
	}
}

export default AboutContent;

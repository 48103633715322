import React, {PureComponent} from 'react';
import './VideoBlock.scss';
import {inject, observer} from "mobx-react";
import { Player } from 'video-react';

@inject('Store')
@observer
class VideoBlock extends PureComponent {
	render() {
		const {Store} = this.props;
		return (
			<section className="video_block">
				<h3>{Store.content.zagolovok}</h3>
				<p>{Store.content.opisanie_pod_zagolovkom}</p>
				<div className="video_block_container">
					<Player
						playsInline
						poster={Store.content.kartinka_video}
						src={Store.content.video}
					/>
				</div>
			</section>
		);
	}
}

export default VideoBlock;

import React, {PureComponent, Fragment} from 'react';
import './Test.scss';
import Button from "../Button";
import {inject, observer} from "mobx-react";
import Interweave from 'interweave';

@inject('Store')
@observer
class Test extends PureComponent {
	constructor(props) {
		super(props);
		const {Store} = this.props;
		this.state.max_questions = Store.content.voprosy.length + 1;
	}

	state = {
		counter: 0,
		yes: 0,
		no: 0,
		max_questions: 0,
	}

	renderQuestion = (counter) => {
		const {Store} = this.props;
		return Store.content.voprosy[counter -1].nazvanie_voprosa
	}

	agreeButton = () => {
		this.setState({
			...this.state,
			yes: this.state.yes + 1,
			counter: this.state.counter + 1,
		})
	}

	denyButton = () => {
		this.setState({
			...this.state,
			no: this.state.no + 1,
			counter: this.state.counter + 1,
		})
	}

	startTest = () => {
		this.setState({...this.state, counter: 1});
	}

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<section id="test" className="test_block">
					{
						this.state.counter === this.state.max_questions ? (
							<Fragment>
								<div className="test_block_counter">Результат теста<br/>Да: {this.state.yes} / Нет: {this.state.no}</div>
								<div className="test_block_question"><Interweave content={Store.content.zagolovok_rezultata_testa}/></div>
								<div className="test_block_answer"><Interweave content={Store.content.tekst_rezultata_testa}/></div>
							</Fragment>
						)	: (
							<Fragment>
								<div className="test_block_counter">
									{
										this.state.counter === 0 ? 'Тест' : `Вопрос ${this.state.counter} из ${this.state.max_questions - 1}`
									}
								</div>
								<div className="test_block_question">
									{
										this.state.counter === 0 ? <Interweave content={Store.content.zagolovok_starta_testirovaniya} /> :
											this.renderQuestion(this.state.counter)
									}
								</div>

								<div className={`test_block_buttons ${this.state.counter === 0 ? 'test_start' : ''}`}>
									{
										this.state.counter === 0 ? <Button width={235} onClick={this.startTest} title="Пройти"/> : (
											<Fragment>
												<Button width={235} onClick={this.agreeButton} title="Да"/>
												<Button width={235} onClick={this.denyButton} title="Нет"/>
											</Fragment>
										)
									}
								</div>
							</Fragment>
						)
					}
				</section>
				<div className="test_block_copyright">{Store.content.informacziya_posle_bloka_testa}</div>
			</Fragment>
		);
	}
}

export default Test;

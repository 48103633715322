import React, {PureComponent, Fragment} from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import Modal from "../Modal";

class AboutContentItem extends PureComponent {

	state = {
		visible: false,
	}

	onHandleClick = () => {
		this.setState({visible: !this.state.visible});
	}

	render() {
		const style = {
			backgroundImage: `url(${this.props.pic})`,
		}
		return (
			<Fragment>
				{
					this.state.visible &&
					ReactDOM.createPortal(
						<Modal
							content={this.props.modalContent}
							visible={this.state.visible}
							onClick={() => this.setState({visible: false})}
						/>, document.getElementById('modal')
						)
				}
				<div className="about_content_item">
					<div className="about_content_item_text">
						<h2>{this.props.title}</h2>
						<p>{this.props.content}</p>
						<button onClick={this.onHandleClick}>Читать подробнее</button>
					</div>
					<div style={style} className="about_content_item_pic"/>
				</div>
			</Fragment>

		);
	}
}

AboutContentItem.propTypes = {
	pic: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};

export default AboutContentItem;

import React, {PureComponent} from 'react';

class CenterBlock extends PureComponent {
	render() {
		const style = {
			maxWidth: '1320px',
			margin: '0 auto',
			padding: '0 20px',
			boxSizing: 'border-box'
		}
		return (
			<div style={style} className="center_block">
				{this.props.children}
			</div>
		);
	}
}

export default CenterBlock;

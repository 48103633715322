import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

class Button extends PureComponent {
	render() {
		const style = {
			maxWidth: this.props.width,
			width: '100%',
			padding: this.props.padding,
			margin: this.props.margin,
			boxSizing: 'border-box'
		}
		return (
			<button style={style} onClick={this.props.onClick} className="white_button">
				{this.props.title}
			</button>
		);
	}
}

Button.propTypes = {
	padding: PropTypes.string,
	margin: PropTypes.string,
	width: PropTypes.any.isRequired,
	onClick: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

Button.defaultProps = {
	width: '100%',
}

export default Button;

import React, {PureComponent} from 'react';
import './IconBox.scss';
import Interweave from 'interweave';
import IconBoxItem from "./IconBoxItem";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class IconBox extends PureComponent {
	render() {
		const {Store} = this.props;
		return (
			<section className="icon_box">
				<h3><Interweave content={Store.content.zagolovok_graph} /></h3>
				<div className="icon_box_subtext">
					<Interweave content={Store.content.opisanie_pod_zagolovkom_graph}/>
				</div>
				<div className="icon_box_graphics">
					{
						Store.content.ikonki.map((item, index) => {
							return (
								<IconBoxItem
									key={index}
									pic={item.ikonka}
									content={item.opisanie}
									overlay={item.overlej_ikonki}
								/>
							)
						})
					}
				</div>
				<div className="icon_box_subtext icon_box_second">
					<Interweave content={Store.content.opisanie_pered_ikonkami}/>
				</div>
				<div className="icon_box_graphics">
					{
						Store.content.ikonki_2.map((item, index) => {
							return (
								<IconBoxItem
									className="icon_box_item2"
									key={index}
									pic={item.ikonka}
									content={item.opisanie}
									overlay={item.overlej_ikonki}
								/>
							)
						})
					}
				</div>
			</section>
		);
	}
}

export default IconBox;
